import { newPostApi } from "../Api/api"

const SET_IS_FETCHING = 'SET_IS_FETCHING'
const SET_SERVER_ERROR = 'SET_SERVER_ERROR'
const SET_SERVER_RESPONSE = 'SET_SERVER_RESPONSE'
const SET_SEARCHING_CITIES = 'SET_SEARCHING_CITIES'
const SET_NP_WAREHOUSES = 'SET_NP_WAREHOUSES'
const SET_IS_OPEN_BURGER = 'SET_IS_OPEN_BURGER'

let initialState = {
    isFetching: false,
    serverError: null,
    serverResponse: null,
    searchingCities: [],
    npWarehouses: [],
    isOpenBurger: false
}

const commonReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IS_FETCHING: {
            return { ...state, isFetching: action.isFetching }
        }
        case SET_SERVER_ERROR: {
            return { ...state, serverError: action.serverError }
        }
        case SET_SERVER_RESPONSE: {
            return { ...state, serverResponse: action.serverResponse }
        }
        case SET_SEARCHING_CITIES: {
            return { ...state, searchingCities: action.searchingCities }
        }
        case SET_NP_WAREHOUSES: {
            return { ...state, npWarehouses: action.npWarehouses }
        }
        case SET_IS_OPEN_BURGER: {
            return { ...state, isOpenBurger: action.isOpenBurger }
        }
        default: 
            return state
    }
}

export const setIsFetching = (isFetching) => ({
    type: SET_IS_FETCHING, isFetching
})
export const setServerError = (serverError) => ({
    type: SET_SERVER_ERROR, serverError
})
export const setServerResponse = (serverResponse) => ({
    type: SET_SERVER_RESPONSE, serverResponse
})
export const setSearchingCities = (searchingCities) => ({
    type: SET_SEARCHING_CITIES, searchingCities
})
export const setNpWarehouses = (npWarehouses) => ({
    type: SET_NP_WAREHOUSES, npWarehouses
})
export const setIsOpenBurger = (isOpenBurger) => ({
    type: SET_IS_OPEN_BURGER, isOpenBurger
})

export const getCities = (searchValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newPostApi.getCities(searchValue)
        dispatch([setSearchingCities(response.data[0].Addresses), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const getWarehouses = (city, number) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newPostApi.getWarehouses(city, number)
        dispatch([setNpWarehouses(response.data), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

// export const getPaymentLink = () => async (dispatch) => {
//     dispatch(setIsFetching(true))
//     try {
//         let response = await platonApi.getDonateLink()
//         dispatch([setIsFetching(false)])
//     }catch(err) {
//         dispatch(setIsFetching(false))
//     }
// }

export default commonReducer