import Container from "../../Components/UI/Container/Container"
import Text from "../../Components/UI/Text/Text/Text"
import classes from "./Thankyou.module.css"

const Thankyou = (props) => {
    return (
        <Container className={classes.main}>
            <h3>Дякуємо, ми отримали ваше замовлення</h3>
            <Text type="p1">Ми зв’яжемось з вами протягом 24 годин</Text>
            <Text type="p1" className={classes.foot}>З любв’ю, <span style={{ color: "#F26142" }}>PUZZ</span></Text>
        </Container>
    )
}

export default Thankyou