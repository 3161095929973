import { AnimatePresence } from 'framer-motion';
import { lazy, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Components/Common/Footer/Footer';
import Burger from './Components/Common/Navbar/Burger/Burger';
import Navbar from './Components/Common/Navbar/Navbar';
import ScrollToTop from './Components/Common/ScrollToTop';
import AdminLogin from './Pages/AdminLogin/AdminLogin';
import ThankyouContainer from './Pages/Thankyou/ThankyouContainer';
import { setIsOpenBurger } from './Redux/commonReducer';
import { setCartData } from './Redux/itemsReducer';
import { getCartFromLc } from './Utils/cartLC';
import MetaFbPixel from './Utils/meta/MetaFbPixel';
const ItemsContainer = lazy(() =>
  import('./Components/Admin/Items/ItemsContainer')
);
const AdminRoute = lazy(() => import('./Components/AdminRoute'));
const AdminCategoriesContainer = lazy(() =>
  import('./Components/Admin/Categories/AdminCategoriesContainer')
);
const HomeContainer = lazy(() => import('./Pages/Home/HomeContainer'));
const CatalogContainer = lazy(() => import('./Pages/Catalog/CatalogContainer'));
const ItemContainer = lazy(() => import('./Pages/Item/ItemContainer'));
const CartContainer = lazy(() => import('./Pages/Cart/CartContainer'));
const AdminKeysContainer = lazy(() =>
  import('./Components/Admin/Keys/AdminKeysContainer')
);
const AdminPromocodesContainer = lazy(() =>
  import('./Components/Admin/Promocodes/AdminPromocodesContainer')
);
const CheckoutContainer = lazy(() =>
  import('./Pages/Checkout/CheckoutContainer')
);
const NotFound = lazy(() => import('./Pages/NotFound/NotFound'));
const PrivacyPolicy = lazy(() => import('./Pages/Info/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./Pages/Info/TermsOfUse'));
const PaymentAndDelivery = lazy(() =>
  import('./Pages/Info/PaymentAndDelivery')
);
const ReturnAndExchanges = lazy(() =>
  import('./Pages/Info/ReturnAndExchanges')
);
const AdminOrdersContainer = lazy(() =>
  import('./Components/Admin/Orders/AdminOrdersContainer')
);

const App = (props) => {
  const { setCartData, isOpenBurger, setIsOpenBurger } = props;

  const aboutRef = useRef();
  const aboutGlukRef = useRef();
  const contactsRef = useRef();

  useEffect(() => {
    setCartData(getCartFromLc());
  }, []);

  return (
    <div>
      <MetaFbPixel />
      <AnimatePresence initial={false} mode="wait" exitBeforeEnter={false}>
        <Router>
          <ScrollToTop />
          <Navbar
            aboutRef={aboutRef}
            aboutGlukRef={aboutGlukRef}
            contactsRef={contactsRef}
          />
          {isOpenBurger && (
            <Burger
              onClose={() => setIsOpenBurger(!isOpenBurger)}
              aboutRef={aboutRef}
              aboutGlukRef={aboutGlukRef}
              contactsRef={contactsRef}
            />
          )}
          <div className="main">
            <Routes>
              <Route
                path="/"
                element={
                  <HomeContainer
                    aboutRef={aboutRef}
                    aboutGlukRef={aboutGlukRef}
                  />
                }
              />
              <Route path="/catalog" element={<CatalogContainer />} />
              <Route path="/item/:nameCode" element={<ItemContainer />} />
              <Route path="/cart" element={<CartContainer />} />
              <Route path="/checkout" element={<CheckoutContainer />} />
              <Route
                path="/thankyou/:orderId"
                element={<ThankyouContainer />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route
                path="/payment-and-delivery"
                element={<PaymentAndDelivery />}
              />
              <Route
                path="/returns-and-exchanges"
                element={<ReturnAndExchanges />}
              />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="admin" element={<AdminRoute />}>
                <Route path="items" element={<ItemsContainer />} />
                <Route
                  path="categories"
                  element={<AdminCategoriesContainer />}
                />
		<Route
                  path="keys"
                  element={<AdminKeysContainer />}
                />
                <Route
                  path="promocodes"
                  element={<AdminPromocodesContainer />}
                />
                <Route path="orders" element={<AdminOrdersContainer />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer
            aboutRef={aboutRef}
            aboutGlukRef={aboutGlukRef}
            contactsRef={contactsRef}
          />
        </Router>
      </AnimatePresence>
    </div>
  );
};

let mapStateToProps = (state) => ({
  isOpenBurger: state.common.isOpenBurger,
});

export default connect(mapStateToProps, {
  setCartData,
  setIsOpenBurger,
})(App);
