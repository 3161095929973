import Button from "@mui/material/Button"
import classes from "./CustomButton.module.css"
import { cx } from "../../../Utils/classnames"
import { HiArrowDownRight } from "react-icons/hi2"
import Text from "../Text/Text/Text"

const CustomButton = (props) => {
    const { size, text, className, onClick, disabled = false, type = "button", showIcon = true } = props

    return (
        <div className={cx(
            classes.main,
            size === "sm" ? classes.sm : "",
            size === "md" ? classes.md : "",
            size === "lg" ? classes.lg : "",
            className
        )}>
            <Button 
                className={cx(classes.button)}
                onClick={onClick}
                disabled={disabled}
                type={type}
            >
                {size === "sm" && <Text type="p3">{text}</Text>}
                {size === "md" && <Text type="p2">{text}</Text>}
                {size === "lg" && <Text type="p1">{text}</Text>}
            </Button>
            {showIcon && (
                <Button
                    onClick={onClick}
                    className={cx(classes.button, classes.iconButton)}
                    disabled={disabled}
                    type={type}
                >
                    <HiArrowDownRight/>
                </Button>
            )}
        </div>
    )
}

export default CustomButton