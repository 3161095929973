export const addCartToLc = (cart) => {
    localStorage.setItem("gluk_cart", JSON.stringify(cart))
}

export const getCartFromLc = () => {
    const cart = localStorage.getItem("gluk_cart")
    if (cart) {
        return JSON.parse(cart)
    }
    return []
}