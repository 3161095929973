import axios from 'axios';

import { signInWithEmailAndPassword } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { formatPrice } from '../Utils/parsePrice';
import { auth, db, storage } from '../firebase-config';

const keysCollectionRef = collection(db, 'keys');
const itemsCollectionRef = collection(db, 'items');
const categoriesCollectionRef = collection(db, 'categories');
const promocodesCollectionRef = collection(db, 'promocodes');
const ordersCollectionRef = collection(db, 'orders');

const newPostKey = '4cee2b513b6adbf0ba793123964eb2cc';

const tgToken = '7143907174:AAHdjrKwgmODKYcVAffWiABJcUgt26C4WwU';
const chatId = '-1001985759811';
const tgUrl = 'https://api.telegram.org/bot' + tgToken + '/sendMessage';

export const imageApi = {
  async deleteImage(url, folderName) {
    let fileName = '';
    if (folderName === 'photos') {
      fileName = url.split('?')[0].split('%')[1].slice(2);
    } else {
      fileName = decodeURIComponent(
        url.split('/o/')[1].split('?alt')[0].split('videos')[1]
      );
    }
    let pictureRef = ref(storage, `/${folderName}/${fileName}`);
    await deleteObject(pictureRef);
  },
};

export const authApi = {
  login(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => userCredential.user
    );
  },
  async me() {
    auth.onAuthStateChanged((authUser) => authUser);
  },
  logout() {
    auth
      .signOut()
      .then(() => {
        console.log('User has been logged out.');
        return true;
      })
      .catch((error) => {
        console.error('Logout error:', error);
        return false;
      });
  },
};

export const keysApi = {
  async getKeys() {
    const data = await getDocs(keysCollectionRef);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  },
  async addKey(data) {
    let response = await addDoc(keysCollectionRef, data);
    let created = { ...data, id: response.id };
    return created;
  },
  async updateKey(id, data) {
    const keyDoc = doc(db, 'keys', id);
    await updateDoc(keyDoc, data);

    let updated = { ...data, id: id };

    return updated;
  },
  async deleteKey(id) {
    const keyDoc = doc(db, 'keys', id);
    await deleteDoc(keyDoc);
  },
};

export const itemsApi = {
  async getItems() {
    const data = await getDocs(itemsCollectionRef);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  },
  async addItem(data) {
    let response = await addDoc(itemsCollectionRef, data);
    let created = { ...data, id: response.id };
    return created;
  },
  async updateItem(id, data) {
    const itemDoc = doc(db, 'items', id);
    await updateDoc(itemDoc, data);

    let updated = { ...data, id: id };

    return updated;
  },
  async deleteItem(id) {
    const itemDoc = doc(db, 'items', id);
    await deleteDoc(itemDoc);
  },
};

export const categoriesApi = {
  async getCategories() {
    const data = await getDocs(categoriesCollectionRef);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  },
  async addCategory(data) {
    let response = await addDoc(categoriesCollectionRef, data);
    let created = { ...data, id: response.id };
    return created;
  },
  async updateCategory(id, data) {
    const categoryDoc = doc(db, 'categories', id);
    await updateDoc(categoryDoc, data);

    let updated = { ...data, id: id };

    return updated;
  },
  async deleteCategory(id) {
    const categoryDoc = doc(db, 'categories', id);
    await deleteDoc(categoryDoc);
  },
};

export const promocodesApi = {
  async getPromocodes() {
    const data = await getDocs(promocodesCollectionRef);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  },
  async addPromocode(data) {
    let response = await addDoc(promocodesCollectionRef, data);
    let created = { ...data, id: response.id };
    return created;
  },
  async updatePromocode(id, data) {
    const promocodeDoc = doc(db, 'promocodes', id);
    await updateDoc(promocodeDoc, data);

    let updated = { ...data, id: id };

    return updated;
  },
  async deletePromocode(id) {
    const promocodeDoc = doc(db, 'promocodes', id);
    await deleteDoc(promocodeDoc);
  },
};

export const ordersApi = {
  async getOrders() {
    const sortedOrdersQuery = query(
      ordersCollectionRef,
      orderBy('date', 'desc')
    );
    const data = await getDocs(sortedOrdersQuery);
    return data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  },
  async addOrder(data) {
    let response = await addDoc(ordersCollectionRef, data);
    let created = { ...data, id: response.id };
    return created;
  },
  async updateOrder(id, data) {
    const orderDoc = doc(db, 'orders', id);
    await updateDoc(orderDoc, data);

    let updated = { ...data, id: id };

    return updated;
  },
  async deleteOrder(id) {
    const orderDoc = doc(db, 'orders', id);
    await deleteDoc(orderDoc);
  },
  async sendTgMessage(order) {
    let message = `
    -------------------------------
    <strong>Нове замовлення!</strong>\n
    <strong>Замовник</strong>\n
    <strong>Ім'я</strong>: ${order.firstName} ${order.lastName}
    <strong>Email</strong>: ${order.email}
    <strong>Телефон</strong>: ${order.phone}\n
    <strong>Деталі замовлення</strong>\n
    <strong>Ціна</strong>: ${formatPrice(order.totalPrice)} грн.
    <strong>Знижка</strong>: ${formatPrice(order.discount)} грн.
    <strong>Адреса доставки</strong>: ${order.city.Present}, ${
      order.warehouse.Description
    }\n
    <strong>До сплати</strong>: ${formatPrice(
      order.totalPrice - order.discount
    )} грн.\n\n
    <strong>Товари</strong>: ${order.items.map(
      (el, index) =>
        `${index + 1}. ${el.name}, https://${window.location.host}/item/${
          el.nameCode
        }${el.photos.length > 1 ? `?color=${el.photos[0].color}` : ''}\n`
    )}`;

    const data = {
      chat_id: chatId,
      text: message,
      parse_mode: 'HTML',
    };
    return axios.post(tgUrl, data).then((response) => response.data);
  },
};

export const newPostApi = {
  getCities(value) {
    const data = {
      apiKey: newPostKey,
      modelName: 'Address',
      calledMethod: 'searchSettlements',
      methodProperties: {
        CityName: value,
        Limit: '150',
        Page: '1',
      },
    };

    return axios
      .post(`https://api.novaposhta.ua/v2.0/json/`, data)
      .then((resposne) => resposne.data);
  },
  getWarehouses(city, number) {
    const data = {
      apiKey: newPostKey,
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityName: city,
        FindByString: `№${number}`,
        Limit: '150',
        Page: '1',
      },
    };

    return axios
      .post(`https://api.novaposhta.ua/v2.0/json/`, data)
      .then((resposne) => resposne.data);
  },
};

export const createMonoBankPaymentUrl = async (order) => {
  try {
    const requestData = {
      amount: (order.totalPrice - order.discount) * 100,
      ccy: 980,
      merchantPaymInfo: {
        reference: order.orderId,
        destination: `With discount PUZZ, ${order.email}`,
        comment: 'Покупка щастя',
        basketOrder: order.items.map((el) => ({
          name: el.name,
          qty: 1,
          icon: el.photos ? el.photos[0].photos[0] : '',
          sum: el.price * 100,
          code: el.nameCode,
          unit: 'шт.',
        })),
      },
      redirectUrl: `http://${window.location.host}/thankyou/${order.orderId}`,
      webHookUrl: `https://www.puzz-drums.com/api/api.php`,
      validity: 3600,
      paymentType: 'debit',
    };

    const response = await axios
      .post(
        'https://api.monobank.ua/api/merchant/invoice/create',
        requestData,
        {
          headers: {
            'X-Token': 'mC1AO_0tQPaMFap30xEOAsQ',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => response.data);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentStatus = (invoiceId) => {
  return axios
    .get(
      `https://api.monobank.ua/api/merchant/invoice/payment-info?invoiceId=${invoiceId}`,
      {
        headers: {
          'X-Token': 'mC1AO_0tQPaMFap30xEOAsQ',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response.data);
};
