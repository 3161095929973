import { itemsApi } from '../Api/api';
import {
  setIsFetching,
  setServerError,
  setServerResponse,
} from './commonReducer';

const SET_ITEMS_DATA = 'SET_ITEMS_DATA';
const SET_NEW_ITEM = 'SET_NEW_ITEM';
const SET_CART_DATA = 'SET_CART_DATA';

let initialState = {
  items: [],
  newItem: null,
  cart: [],
};

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS_DATA: {
      return { ...state, items: action.items };
    }
    case SET_NEW_ITEM: {
      return { ...state, newItem: action.newItem };
    }
    case SET_CART_DATA: {
      return { ...state, cart: action.cart };
    }
    default: {
      return state;
    }
  }
};

export const setItemsData = (items) => ({
  type: SET_ITEMS_DATA,
  items,
});

export const setNewItem = (newItem) => ({
  type: SET_NEW_ITEM,
  newItem,
});
export const setCartData = (cart) => ({
  type: SET_CART_DATA,
  cart,
});

export const getItems = () => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    let response = await itemsApi.getItems();
    dispatch([setItemsData(response), setIsFetching(false)]);
  } catch (err) {
    dispatch(setIsFetching(false));
  }
};

export const addItem = (data) => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    let response = await itemsApi.addItem(data);
    dispatch([
      setNewItem(response),
      setServerResponse('Збережено.'),
      setIsFetching(false),
    ]);
  } catch (err) {
    dispatch([setIsFetching(false), setServerError('Помилка сервера!')]);
  }
};

export const updateItem = (id, data) => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    let response = await itemsApi.updateItem(id, data);
    dispatch([
      setNewItem(response),
      setServerResponse('Збережено.'),
      setIsFetching(false),
    ]);
  } catch (err) {
    console.log(err);
    dispatch([setIsFetching(false), setServerError('Помилка сервера!')]);
  }
};

export const removeItem = (id) => async (dispatch) => {
  dispatch(setIsFetching(true));
  try {
    await itemsApi.deleteItem(id);
    dispatch([setIsFetching(false), setServerResponse('Видалено.')]);
  } catch (err) {
    dispatch([setIsFetching(false), setServerError('Помилка сервера!')]);
  }
};

export default itemsReducer;
