import { getPaymentStatus, ordersApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_ORDERS_DATA = "SET_ORDERS_DATA"
const SET_NEW_ORDER = "SET_NEW_ORDER"
const SET_STATUS = "SET_STATUS"

let initialState = {
    orders: [],
    newOrder: null,
    status: "pending"
}

const ordersReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ORDERS_DATA: {
            return { ...state, orders: action.orders }
        }
        case SET_NEW_ORDER: {
            return { ...state, newOrder: action.newOrder }
        }
        case SET_STATUS: {
            return { ...state, status: action.status }
        }
        default: {
            return state
        }
    }
}


export const setOrdersData = (orders) => ({
    type: SET_ORDERS_DATA, orders
})
export const setNewOrder = (newOrder) => ({
    type: SET_NEW_ORDER, newOrder
})
export const setStatus = (status) => ({
    type: SET_STATUS, status
})

export const getOrders = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.getOrders()
        dispatch([setOrdersData(response), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}

export const addOrder = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.addOrder(data)
        dispatch([setNewOrder(response), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
        return false
    }
}

export const updateOrder = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.updateOrder(id, data)
        dispatch([setNewOrder(response), setIsFetching(false)])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const removeOrder = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await ordersApi.deleteOrder(id)
        dispatch([setIsFetching(false), setServerResponse("Видалено.")])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const checkStatus = (invoiceId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await getPaymentStatus(invoiceId)
        dispatch([setIsFetching(false), setStatus("success")])
    } catch (err) {
        dispatch([setIsFetching(false), setStatus("fail")])
    }
}

export const sendTgMessage = (order) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await ordersApi.sendTgMessage(order)
        dispatch(setIsFetching(false))
    } catch (err) {
        dispatch([setIsFetching(false)])
    }
}

export default ordersReducer