import { initializeApp } from "firebase/app";
import { initializeFirestore } from "@firebase/firestore"
import { getStorage } from "@firebase/storage"
import { getAuth } from "@firebase/auth"

// import "firebase/compat/auth";
// import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDHG1beLFZ2C3BxMhZkI6RIULVu__egxas",
  authDomain: "glukofon-a34bc.firebaseapp.com",
  projectId: "glukofon-a34bc",
  storageBucket: "glukofon-a34bc.appspot.com",
  messagingSenderId: "495130915157",
  appId: "1:495130915157:web:c59ed460dbd972ab20ffdc"
};

const app = initializeApp(firebaseConfig)

export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
})
export const storage = getStorage(app)
export const auth = getAuth(app)