import Modal from "../../../UI/Modal/Modal"
import classes from "./Burger.module.css"
import logo from "../../../../Assets/logo.webp"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"

const Burger = (props) => {
    const { onClose, cart, aboutRef, aboutGlukRef, contactsRef } = props

    const scrollTo = (anchor) => {
        setTimeout(() => {
            if (anchor === "about") {
                aboutRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            } else if (anchor === "gluk") {
                aboutGlukRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            } else if (anchor === "contacts") {
                contactsRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            onClose()
        }, 200)
    }

    return (
        <Modal onClose={onClose} title={<img src={logo} alt="logo"/>} className={classes.main} classNameContent={classes.content}>
            <div className={classes.links}>
                <NavLink onClick={() => scrollTo("catalog")} to="/catalog">Каталог</NavLink>
                <NavLink onClick={() => scrollTo("about")} to="/">Про нас</NavLink>
                <NavLink onClick={() => scrollTo("gluk")} to="/">Про Глюкофони</NavLink>
                <NavLink onClick={() => scrollTo("contacts")} to="/">Контакти</NavLink>
            </div>
            <NavLink onClick={() => scrollTo("")} to="/">Головна</NavLink>
            <NavLink onClick={() => scrollTo("cart")} to="/cart">
                Кошик(<span style={{ color: "#F26142" }}>{cart.length}</span>)
            </NavLink>
        </Modal>
    )
}

let mapStateToProps = (state) => ({
    cart: state.items.cart
})

export default connect(mapStateToProps, {})(Burger)