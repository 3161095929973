import * as React from "react"
const BurgerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <circle cx={15} cy={15} r={14.5} stroke="currentColor" />
    <circle cx={11} cy={11} r={2} fill="currentColor" />
    <circle cx={11} cy={19} r={2} fill="currentColor" />
    <circle cx={19} cy={11} r={2} fill="currentColor" />
    <circle cx={19} cy={19} r={2} fill="currentColor" />
  </svg>
)
export default BurgerIcon
