import { keysApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_KEYS_DATA = "SET_KEYS_DATA"
const SET_NEW_KEY = "SET_NEW_KEY"

let initialState = {
    keys: [],
    newKey: null
}

const keysReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_KEYS_DATA: {
            return { ...state, keys: action.keys }
        }
        case SET_NEW_KEY: {
            return { ...state, newKey: action.newKey }
        }
        default: {
            return state
        }
    }
}

export const setKeysData = (keys) => ({
    type: SET_KEYS_DATA, keys
})

export const setNewKey = (newKey) => ({
    type: SET_NEW_KEY, newKey
})

export const getKeys = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await keysApi.getKeys()
        dispatch([setKeysData(response), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const addKey = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await keysApi.addKey(data)
        dispatch([setNewKey(response), setServerResponse("Збережено."), setIsFetching(false)])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const updateKey = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await keysApi.updateKey(id, data)
        dispatch([setNewKey(response), setServerResponse("Збережено."), setIsFetching(false)])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const removeKey = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await keysApi.deleteKey(id)
        dispatch([setIsFetching(false), setServerResponse("Видалено.")])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export default keysReducer