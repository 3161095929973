import { authApi } from "../Api/api"
import { setIsFetching, setServerError } from "./commonReducer"

const SET_USER_DATA = 'SET_USER_DATA'
const SET_IS_AUTH = 'SET_IS_AUTH'
const SET_IS_RECEIVED_AUTH_RESPONSE = 'SET_IS_RECEIVED_AUTH_RESPONSE'

let initialState = {
    user: null,
    isAuth: false,
    isReceivedAuthResponse: false
}

let adminReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER_DATA: {
            return { ...state, user: action.user }
        }
        case SET_IS_AUTH: {
            return { ...state, isAuth: action.isAuth }
        }
        case SET_IS_RECEIVED_AUTH_RESPONSE : {
            return { ...state, isReceivedAuthResponse: action.isReceivedAuthResponse }
        }
        default:
            return state
    }
}

export const setUserData = (user) => ({
    type: SET_USER_DATA, user
})
export const setIsAuth = (isAuth) => ({
    type: SET_IS_AUTH, isAuth
})
export const setIsReceivedAuthResponse = (isReceivedAuthResponse) => ({
    type: SET_IS_RECEIVED_AUTH_RESPONSE, isReceivedAuthResponse
})

export const login = (email, password) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await authApi.login(email, password)
        localStorage.user = JSON.stringify(response)
        dispatch([setUserData(response), setIsAuth(true), setServerError(null), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError("Неправильний логін або пароль!"), setIsFetching(false)])
    }
}

export const me = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let user = JSON.parse(localStorage.user)
        await authApi.me(user)
        dispatch([setUserData(user), setIsAuth(true), setIsReceivedAuthResponse(true),  setIsFetching(false)])
    }catch(err) {
        dispatch([setIsReceivedAuthResponse(true), setIsFetching(false)])
    }
}

export const logout = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    await authApi.logout()
    localStorage.user = ''
    dispatch([setIsAuth(false), setUserData(null)])
    dispatch(setIsFetching(false))
}

export default adminReducer