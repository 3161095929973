import { TextField } from '@mui/material'
import React from 'react'

const CustomInput = (props) => {
    const {
        onChange,
        value,
        multiline = false,
        rows = 5,
        error,
        placeholder,
        label,
        type = "text",
        regex = null,
        endAdornment = false,
        helperText
    } = props

    const handleChange = (e) => {
        if(regex === "number") {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            onChange(onlyNums)
        }else {
            onChange(e.target.value)
        }
    }

    const material = {
        '& label.Mui-focused': {
            color: '#F26142'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F26142' 
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            background: "white",
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            marginTop: "5px",
        }
    }

    return (
        <TextField
            onChange={handleChange}
            value={value}
            multiline={multiline}
            rows={rows}
            error={!!error}
            helperText={helperText ? helperText : (error ? error.message : null)}
            variant="outlined"
            placeholder={placeholder}
            label={label}
            sx={{ ...material }}
            type={type}
            InputProps={{
                endAdornment: endAdornment
            }}
        />
    )
}

export default CustomInput