import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Preloader from '../../Components/Common/Preloader/Preloader';
import { setCartData } from '../../Redux/itemsReducer';
import {
  checkStatus,
  getOrders,
  sendTgMessage,
  updateOrder,
} from '../../Redux/ordersReducer';
import Thankyou from './Thankyou';

const ThankyouContainer = (props) => {
  const {
    getOrders,
    orders,
    checkStatus,
    status,
    updateOrder,
    setCartData,
    sendTgMessage,
  } = props;

  const [currentOrder, setCurrentOrder] = useState({});
  const { orderId } = useParams();

  const navigate = useNavigate();

  const handlePurchaseEvent = (order) => {
    const productIds = order.items.map(({ id }) => id);

    window.fbq('track', 'Purchase', {
      content_ids: productIds,
      content_name: 'покупка',
      content_type: 'product',
      currency: 'UAH',
      value: order.totalPrice,
    });
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      const order = orders.find((el) => el.orderId === orderId);
      setCurrentOrder(order);
      if (order) {
        if (order.status === 'paid') return;

        handlePurchaseEvent(order);
        checkStatus(order.invoiceId);
      } else {
        navigate('/');
      }
    }
  }, [orders]);

  useEffect(() => {
    if (status === 'fail') {
      navigate('/');
    } else if (status === 'success') {
      if (currentOrder) {
        if (currentOrder.status === 'paid') return;

        updateOrder(currentOrder.id, {
          ...currentOrder,
          status: 'paid',
        });
        sendTgMessage(currentOrder);
        localStorage.setItem('gluk_cart', []);
        setCartData([]);
      }
    }
  }, [status]);

  return (
    <>
      {status === 'pending' && currentOrder.status !== 'paid' ? (
        <Preloader />
      ) : (
        <Thankyou />
      )}
    </>
  );
};

let mapStateToProps = (state) => ({
  orders: state.orders.orders,
  status: state.orders.status,
});

export default connect(mapStateToProps, {
  getOrders,
  checkStatus,
  updateOrder,
  setCartData,
  sendTgMessage,
})(ThankyouContainer);
