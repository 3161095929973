import { IconButton } from "@mui/material"
import classes from "./Modal.module.css"
import { MdOutlineClose } from 'react-icons/md'
import Overflow from "../Overflow/Overflow"
import { cx } from "../../../Utils/classnames"

const Modal = (props) => {
    const { title, onClose, children, className, classNameContent } = props

    return (
        <Overflow>
            <div className={cx(classes.main, className)}>
                <div className={classes.header}>
                    <h4>{title}</h4>
                    <IconButton onClick={onClose}>
                        <MdOutlineClose/>
                    </IconButton>
                </div>
                <div className={cx(classes.content, classNameContent)}>
                    {children}
                </div>
            </div>
        </Overflow>
    )
}

export default Modal