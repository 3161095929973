import classes from "./Link.module.css"
import { NavLink } from "react-router-dom"
import { cx } from "../../../../Utils/classnames"
import Text from "../Text/Text"

const Link = (props) => {
    const { icon, text, className, to, isNavHome = false, target = "_self", cart, onClick } = props

    return (
        <NavLink
            target={target}
            to={to}
            className={cx(classes.main, isNavHome ? classes.home : "", className)}
            onClick={onClick}
        >
            {icon && icon}
            <Text type="p3">
                {text}
                {to === "/cart" ? (
                    <>
                        &nbsp;(<span className={classes.cart}>{cart.length}</span>)
                    </>
                ) : ""}
            </Text>
        </NavLink>
    )
}

export default Link