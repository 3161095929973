import { HiSquares2X2 } from 'react-icons/hi2';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cx } from '../../../Utils/classnames';
import CustomButton from '../../UI/Button/CustomButton';
import Container from '../../UI/Container/Container';
import Header from '../../UI/Text/Header/Header';
import Link from '../../UI/Text/Link/Link';
import Text from '../../UI/Text/Text/Text';
import classes from './Footer.module.css';

import applepay from '../../../Assets/applepay.svg';
import gpay from '../../../Assets/gpay.svg';
import mastercard from '../../../Assets/mastercard.svg';
import monopay from '../../../Assets/monopay.svg';
import visa from '../../../Assets/visa.svg';

const Footer = (props) => {
  const { cart, aboutRef, aboutGlukRef, contactsRef } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const scrollTo = (anchor) => {
    setTimeout(() => {
      if (anchor === 'about') {
        aboutRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (anchor === 'gluk') {
        aboutGlukRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        contactsRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 200);
  };

  const hideFooter =
    location.pathname.includes('admin') ||
    (location.pathname !== '/' &&
      location.pathname !== '/catalog' &&
      location.pathname !== '/cart' &&
      location.pathname !== '/checkout' &&
      location.pathname !== '/privacy-policy' &&
      location.pathname !== '/terms-of-use' &&
      location.pathname !== '/returns-and-exchanges' &&
      location.pathname !== '/payment-and-delivery' &&
      !location.pathname.includes('item'));

  return (
    <footer
      className={cx(classes.main, hideFooter ? classes.none : '')}
      ref={contactsRef}
    >
      <Container className={classes.container}>
        <div className={classes.header}>
          <Header type="h2">На зв'язку</Header>
          <div className={classes.right}>
            <div className={classes.side}>
              <Link
                to={location.pathname !== '/catalog' ? '/catalog' : '/'}
                icon={<HiSquares2X2 />}
                text={location.pathname !== '/catalog' ? 'Каталог' : 'Головна'}
              />
              <div className={classes.cart}>
                <Link to="/cart" text={`Кошик`} isNavHome={false} cart={cart} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sense}>
          <Text className={classes.senseText} type="p1">
            Всередині кожного з нас живе музика. Вона народжується з глибинних
            почуттів, ритму серцебиття і споконвічної гармонії
          </Text>
          <CustomButton
            onClick={() => navigate('/catalog')}
            text="Переглянути"
            size="sm"
          />
        </div>
        <div className={classes.info}>
          <div className={classes.left}>
            <div className={classes.column}>
              <Link to="/privacy-policy" text="ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ" />
              <Link to="/terms-of-use" text="ДОГОВІР ОФЕРТИ" />
            </div>
            <div className={classes.column}>
              <Link to="/returns-and-exchanges" text="ПОВЕРНЕННЯ ТА ОБМІН" />
              <Link to="/payment-and-delivery" text="ОПЛАТА І ДОСТАВКА" />
            </div>
            <div className={classes.services}>
              <img src={monopay} alt="monopay" />
              <img src={applepay} alt="applepay" />
              <img src={gpay} alt="gpay" />
              <img src={visa} alt="visa" />
              <img src={mastercard} alt="mastercard" />
            </div>
          </div>
          <div className={classes.right}>
            <div className={cx(classes.side, classes.titles)}>
              <Text type="p3">КОНТАКТИ</Text>
              <Text type="p3">МЕНЮ</Text>
            </div>
            <div className={classes.side}>
              <Link
                className={classes.mailLink}
                to="mailto:puzzdrums@gmail.com"
                text="puzzdrums@gmail.com"
              />
            </div>
            <div className={classes.side}>
              <Link to="phone:+380957429440" text="+380 95 742 94 40" />
            </div>
            <div className={classes.side}>
              <Link to="https://t.me/puzzman" target="_blank" text="TELEGRAM" />
              <Link onClick={() => scrollTo('about')} to="/" text="Про нас" />
            </div>
            <div className={classes.side}>
              <Link
                to="https://instagram.com/puzz_drums"
                target="_blank"
                text="INSTAGRAM"
              />
              <Link
                onClick={() => scrollTo('gluk')}
                to="/"
                text="Про глюкофони"
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Text type="p3" className={classes.year}>
            © {new Date().getFullYear()} — PUZZ.DRUMS
          </Text>
          <div className={classes.servicesMobile}>
            <img src={monopay} alt="monopay" />
            <img src={applepay} alt="applepay" />
            <img src={gpay} alt="gpay" />
            <img src={visa} alt="visa" />
            <img src={mastercard} alt="mastercard" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

let mapStateToProps = (state) => ({
  cart: state.items.cart,
});

export default connect(mapStateToProps, {})(Footer);
