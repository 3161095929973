import { cx } from "../../../../Utils/classnames"
import classes from "./Header.module.css"

const Header = (props) => {
    const { type, children, className } = props

    return (
        <>
            {type === "h1" && <h1 className={cx(classes.h1, className)}>{children}</h1>}
            {type === "h2" && <h2 className={cx(classes.h2, className)}>{children}</h2>}
        </>
    )
}

export default Header