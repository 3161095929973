import { NavLink } from "react-router-dom"
import Container from "../../UI/Container/Container"
import classes from "./Navbar.module.css"
import { useLocation } from "react-router"
import { cx } from "../../../Utils/classnames"

import { HiSquares2X2 } from "react-icons/hi2"

import logo from "../../../Assets/logo.png"
import Link from "../../UI/Text/Link/Link"
import { connect } from "react-redux"

import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"
import { IconButton } from "@mui/material"

import BurgerIcon from "../Icons/BurgerIcon"
import { setIsOpenBurger } from "../../../Redux/commonReducer"

const Navbar = (props) => {
    const { cart, aboutRef, aboutGlukRef, contactsRef, setIsOpenBurger } = props

    const location = useLocation()

    const scrollTo = (anchor) => {
        setTimeout(() => {
            if (anchor === "about") {
                aboutRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            } else if (anchor === "gluk") {
                aboutGlukRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            } else {
                contactsRef.current.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }, 200)
    }

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return (
        <nav
            className={cx(classes.main, location.pathname == "/" ? classes.absolute : "", location.pathname.includes("admin") ? classes.none : '')}
            data-aos="fade-down"
            data-aos-duration="2000"
        >
            <Container className={classes.container}>
                <NavLink to="/">
                    <img src={logo} alt="logo" className={classes.logo}/>
                </NavLink>
                <div className={classes.catalogHome}>
                    <Link
                        to={location.pathname !== "/catalog" ? "/catalog" : "/"}
                        icon={<HiSquares2X2/>}
                        text={location.pathname !== "/catalog" ? "Каталог" : "Головна"}
                        isNavHome={location.pathname === "/"}
                    />
                </div>
                <div className={classes.rightSide}>
                    <div className={classes.links}>
                        <Link onClick={() => scrollTo("about")} to="/" text="Про нас" isNavHome={location.pathname === "/"}/>
                        <Link onClick={() => scrollTo("gluk")} to="/" text="Про глюкофони" isNavHome={location.pathname === "/"}/>
                        <Link onClick={() => scrollTo("contacts")} to="/" text="Контакти" isNavHome={location.pathname === "/"}/>
                    </div>
                    <div className={classes.cart}>
                        <Link
                            to="/cart"
                            text={`Кошик`}
                            isNavHome={location.pathname === "/"}
                            cart={cart}
                        />
                    </div>
                </div>
                <div className={classes.cartMobile}>
                    <Link
                        to="/cart"
                        text={`Кошик`}
                        isNavHome={location.pathname === "/"}
                        cart={cart}
                    />
                </div>
                <IconButton 
                    className={cx(classes.burger, location.pathname === "/" ? classes.burgerHome : "")}
                    onClick={() => setIsOpenBurger(true)}
                >
                    <BurgerIcon/>
                </IconButton>
            </Container>
        </nav>
    )
}

let mapStateToProps = (state) => ({
    cart: state.items.cart
})

export default connect(mapStateToProps, {
    setIsOpenBurger
})(Navbar)