import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import multi from 'redux-multi';
import thunkMiddleWare from 'redux-thunk';
import adminReducer from './adminReducer';
import categoriesReducer from './categoriesReducer';
import commonReducer from './commonReducer';
import itemsReducer from './itemsReducer';
import keysReducer from './keysReducer';
import ordersReducer from './ordersReducer';
import promocodesReducer from './promocodesReducer';

let reducers = combineReducers({
  admin: adminReducer,
  common: commonReducer,
  keys: keysReducer,
  items: itemsReducer,
  categories: categoriesReducer,
  promocodes: promocodesReducer,
  orders: ordersReducer,
});

let store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunkMiddleWare, multi))
);

window.store = store;

export default store;
