import { promocodesApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_PROMOCODES_DATA = "SET_PROMOCODES_DATA"
const SET_NEW_PROMOCODE = "SET_NEW_PROMOCODE"

let initialState = {
    promocodes: [],
    newPromocode: null
}

const promocodesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PROMOCODES_DATA: {
            return { ...state, promocodes: action.promocodes }
        }
        case SET_NEW_PROMOCODE: {
            return { ...state, newPromocode: action.newPromocode }
        }
        default: {
            return state
        }
    }
}

export const setPromocodesData = (promocodes) => ({
    type: SET_PROMOCODES_DATA, promocodes
})

export const setNewPromocode = (newPromocode) => ({
    type: SET_NEW_PROMOCODE, newPromocode
})

export const getPromocodes = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodesApi.getPromocodes()
        dispatch([setPromocodesData(response), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const addPromocode = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodesApi.addPromocode(data)
        dispatch([setNewPromocode(response), setServerResponse("Збережено."), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
        return false
    }
}

export const updatePromocode = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodesApi.updatePromocode(id, data)
        dispatch([setNewPromocode(response), setServerResponse("Збережено."), setIsFetching(false)])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const removePromocode = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await promocodesApi.deletePromocode(id)
        dispatch([setIsFetching(false), setServerResponse("Видалено.")])
    }catch(err) {
        console.log(err)
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export default promocodesReducer