import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { connect } from "react-redux"
import { useNavigate } from "react-router"
import Preloader from "../../Components/Common/Preloader/Preloader"
import CustomButton from "../../Components/UI/Button/CustomButton"
import CustomInput from "../../Components/UI/Form/CustomInput"
import { login } from "../../Redux/adminReducer"
import classes from "./AdminLogin.module.css"

const AdminLogin = (props) => {
    const { isFetching, login, isAuth } = props

    const { handleSubmit, control } = useForm()

    const navigate = useNavigate()

    const onSubmit = (data) => {
        login(data.email, data.password)
    }

    useEffect(() => {
        if (isAuth) {
            navigate("/admin")
        }
    }, [isAuth])

    return (
        <div className={classes.main}>
            {isFetching && <Preloader/>}
            <h3>Welcome</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Обов'язкове поле!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomInput
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Email"  
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Обов'язкове поле!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomInput
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Password"
                            type="password"  
                        />
                    )}
                />
                <CustomButton size="md" text="Login" type="submit" showIcon={false}/>
            </form>
        </div>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    isAuth: state.admin.isAuth
})

export default connect(mapStateToProps, {
    login
})(AdminLogin)