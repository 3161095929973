import { categoriesApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_CATEGORIES_DATA = "SET_CATEGORIES_DATA"
const SET_NEW_CATEGORY = "SET_NEW_CATEGORY"

let initialState = {
    categories: [],
    newCategory: null
}

const categoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CATEGORIES_DATA: {
            return { ...state, categories: action.categories }
        }
        case SET_NEW_CATEGORY: {
            return { ...state, newCategory: action.newCategory }
        }
        default: {
            return state
        }
    }
}

export const setCategoriesData = (categories) => ({
    type: SET_CATEGORIES_DATA, categories
})

export const setNewCategory = (newCategory) => ({
    type: SET_NEW_CATEGORY, newCategory
})

export const getCategories = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await categoriesApi.getCategories()
        dispatch([setCategoriesData(response), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const addCategory = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await categoriesApi.addCategory(data)
        dispatch([setNewCategory(response), setServerResponse("Збережено."), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
        return false
    }
}

export const updateCategory = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await categoriesApi.updateCategory(id, data)
        dispatch([setNewCategory(response), setServerResponse("Збережено."), setIsFetching(false)])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export const removeCategory = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await categoriesApi.deleteCategory(id)
        dispatch([setIsFetching(false), setServerResponse("Видалено.")])
    }catch(err) {
        dispatch([setIsFetching(false), setServerError("Помилка сервера!")])
    }
}

export default categoriesReducer