import React from 'react'
import classes from './Preloader.module.css'
import preloader from '../../../Assets/preloader.svg'
import Overflow from '../../UI/Overflow/Overflow'

import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"

const Preloader = (props) => {
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return (
        <Overflow className={classes.index}>
            <img
                src={preloader}
                alt="preloader"
                className={classes.main}
                data-aos="zoom-in"
                data-aos-duration="300"
            />
        </Overflow>
    )
}

export default Preloader