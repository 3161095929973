export function formatPrice(price) {
    let priceStr = price.toString();
    
    let parts = [];
    while (priceStr.length > 3) {
        parts.unshift(priceStr.slice(-3));
        priceStr = priceStr.slice(0, -3);
    }
    parts.unshift(priceStr);
    
    return parts.join(' ');
}